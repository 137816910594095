import React from 'react';
import './FooterCOD1.scss';


function Footer() {
  return (
    <footer class="footer">
        <div class="footer-section">
            <p>DESIGN PAR PIXETMOTION</p>
            <p>DÉVELOPPÉ PAR HELLFIRE © 2024</p>
        </div>
        <div class="footer-section">
            <a href="https://twitch.tv/pixetmotion">
                <img src={require("../../../assets/cod1/Background/icon-twitch.webp")} alt="Twitch" />TWITCH.TV/PIXETMOTION
            </a>
        </div>
        <div class="social-media">
            <p>VIENS FOLLOW NOS RÉSEAUX</p>
            <a href="https://discord.gg/htVnjEqqtE"><img src={require("../../../assets/cod1/Background/icon-discord.webp")} alt="Discord" /></a>
            <a href="https://www.instagram.com/twitchpixetmotion/"><img src={require("../../../assets/cod1/Background/icon-insta.webp")} alt="Instagram" /></a>
            <a href="https://www.youtube.com/@pixetmotiongaming"><img src={require("../../../assets/cod1/Background/icon-youtube.webp")} alt="Youtube" /></a>
            <a href="https://twitter.com/pixetmotion"><img src={require("../../../assets/cod1/Background/icon-twitter.webp")} alt="Twitter" /></a>
            <a href="https://www.tiktok.com/@pixetmotion"><img src={require("../../../assets/cod1/Background/icon-tiktok.webp")} alt="Tiktok" /></a>
        </div>
    </footer>
  )
}
 
export default Footer