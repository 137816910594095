import React from 'react';
import './TeamsCOD4.scss';
import AddTeamsCOD4 from "../../../components/COD4-Components/AddTeamsCOD4/AddTeamsCOD4"

function TeamsCOD4() {

  return (
    <section className="teams-cod4">
      <div className='img-container'>
        <img className='img-bannerqualifcod4' src={require("../../../assets/cod4/titles/TEAMS.png")} alt="Logo MATCH" />
      </div>
      <AddTeamsCOD4/>
    </section>
  );
}

export default TeamsCOD4;

