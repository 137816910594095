import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ClassementCOD4.scss'; // Assurez-vous d'importer votre fichier de style CSS ou SCSS ici

const apiUrl = 'https://dev.borezo.info/mohaa/data.php';

const TableauGoalAverage = ({ teams }) => {
  return (
    <div>
      <h2>Classement Goal Average</h2>
      <table>
        <thead>
          <tr>
            <th>Position</th>
            <th>Nom de l'équipe</th>
            <th className='th-average'>Goal Average</th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{team.name}</td>
              <td>{team.average}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TableauClassementGeneral = ({ teams }) => {
  return (
    <div>
      <h2>Classement Général</h2>
      <table>
        
        <thead>
          <tr>
            <th>Position</th>
            <th>Nom de l'équipe</th>
            <th className='th-points'>Points<br /><th className='caption_points'>(Victoire: 3 pts | Match Nul: 1 pts | Défaite: 0 pts)</th></th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{team.name}</td>
              <td>{team.points}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TableauClassementJoueurs = ({ players }) => {
  return (
    <div>
      <h2>Classement Joueurs</h2>
      <table>
        <thead>
          <tr>
            <th>Position</th>
            <th>Nom du joueur</th>
            <th>Kills</th>
            <th>Deaths</th>
            <th>Ratio</th>
          </tr>
        </thead>
        <tbody>
          {players.map((player, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{player.name}</td>
              <td>{player.kills}</td>
              <td>{player.deaths}</td>
              <td>{player.ratio}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const App = () => {
  const [teamsData, setTeamsData] = useState([]);
  const [playersData, setPlayersData] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      axios.get(apiUrl)
        .then(response => {
          const teams = response.data.teams.map(team => ({
            name: team.name,
            points: team.points,
            average: team.average
          }));

          // Trier les équipes par average (du plus grand au plus petit)
          const sortedTeams = [...teams].sort((a, b) => b.average - a.average);

          const players = response.data.players.map(player => ({
            name: player.name,
            kills: player.kills,
            deaths: player.deaths,
            ratio: player.ratio
          }));

          // Trier les joueurs par ratio (du plus grand au plus petit)
          const sortedPlayers = [...players].sort((a, b) => b.ratio - a.ratio);

          setTeamsData(sortedTeams);
          setPlayersData(sortedPlayers);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    };

    // Appel initial de la fonction fetchData
    fetchData();

    // Mise à jour des données toutes les 5 secondes
    const interval = setInterval(fetchData, 5000);

    // Nettoyage de l'intervalle lors du démontage du composant
    return () => clearInterval(interval);
  }, []); // Dépendance vide pour s'exécuter uniquement une fois lors du montage du composant

  return (
    <div className="container-classementcod4">
      <div className='classement-generalcod4'>
        <TableauClassementGeneral teams={teamsData} />
      </div>
      <div className='classement-averagecod4'>
        <TableauGoalAverage teams={teamsData} />
      </div>
      <div className='classement-joueurscod4'>
        <TableauClassementJoueurs players={playersData} />
      </div>
    </div>
  );
};

export default App;
