import React, { useEffect, useState } from 'react';
import './AgentsValorant.scss'; 
import { useOutletContext } from 'react-router-dom'; 

function AgentsValorant() {
  const [players, setPlayers] = useState([]);
  const [teams, setTeams] = useState([]);
  const { searchQuery } = useOutletContext(); // Utiliser useOutletContext pour obtenir searchQuery

  // Fetch les données des joueurs et des équipes
  useEffect(() => {
    // Récupérer les joueurs
    fetch('https://api.supra-event.fr/api/tournament/players')
      .then(response => response.json())
      .then(data => setPlayers(data))
      .catch(error => console.error('Erreur lors de la récupération des joueurs :', error));

    // Récupérer les équipes
    fetch('https://api.supra-event.fr/api/tournament/teams')
      .then(response => response.json())
      .then(data => setTeams(data))
      .catch(error => console.error('Erreur lors de la récupération des équipes :', error));
  }, []);

  // Fonction pour obtenir le logo de l'équipe d'un joueur
  const getTeamLogo = (teamName) => {
    const team = teams.find(t => t.name === teamName);
    return team ? team.logo : '';
  };

  // Filtrer les joueurs en fonction de la recherche
  const filteredPlayers = players.filter(player => 
    player.name.toLowerCase().includes(searchQuery.toLowerCase()) // Filtre par nom (case insensitive)
  );

  return (
    <section className='agents-valorant'>
      <h2 className='title-agents'>Agents</h2>
      <div className="players-list">
        {filteredPlayers.length > 0 ? (
          filteredPlayers.map((player) => {
            const teamLogo = getTeamLogo(player.team);
            const kdRatio = (player.kills / player.deaths).toFixed(2); // Calcul du K/D ratio
            
            return (
              <div key={player._id} className="player-card-valo">
                <img src={player.profileImage} alt={`${player.name} avatar`} className="player-avatar" />
                <h3>{player.name}</h3>
                <img src={teamLogo} alt={`${player.team} logo`} className="team-logo-valo" />
                <div className='stats-players'>
                  <div className='stat-item'>
                    <h3>{player.kills}</h3>
                    <p>Kills</p>
                  </div>
                  <div className="stat-item">
                    <h3>{player.deaths}</h3>
                    <p>Deaths</p>
                  </div>
                  <div className="stat-item">
                    <h3>{kdRatio}</h3>
                    <p>Ratio K/D</p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p className='nothing-players'>Aucun joueur ne correspond à votre recherche.</p>
        )}
      </div>
    </section>
  );
}

export default AgentsValorant;
