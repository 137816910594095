import React from 'react';
import './MatchCOD4.scss';
import MatchMakingCOD4 from "../../../components/COD4-Components/Match-MakingCOD4/Match-MakingCOD4";

function MatchCOD4() {


  return (
    <section className="match-cod4">
      <MatchMakingCOD4 />

    </section>
  );
}

export default MatchCOD4;
