import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons'

import './navbarCOD1.scss'

function Navbar() {

  return (
    <nav className='navbar'>
        <NavLink to="/tournament/cod1/teams" >TEAMS</NavLink>
        <NavLink to="/tournament/cod1/match" >MATCH </NavLink>
        <NavLink to="/tournament/cod1/classement" >CLASSEMENT</NavLink>
        <NavLink to="/tournament/cod1/eliminatoire" >ÉLIMINATOIRE</NavLink>
        <NavLink to="/HallOfFame" ><FontAwesomeIcon icon={faArrowRightToBracket} /></NavLink>
    </nav>
  )
}

export default Navbar
