import React from 'react';
import './FinalCOD1.scss';


function Final() {
  return (
    <div>
      <h2 className="final-title">FINALE</h2>
      <article className="final">
        {/* ROUND 1 */}
        <div className="team-section">
          <div className="team-logo" style={{ backgroundImage: "url('/assets/mapcod/lesgaulois_nobg.png')" }}></div>
          <div className="team-info">
            <span className="team-name">&nbsp;&nbsp;Les Gaulois&nbsp;&nbsp;</span>
            <div className="team-finalcount " style={{ backgroundColor: 'green' }}>1&nbsp;1</div>
            <span className="versus">VS</span>
            <div className="team-finalcount " style={{ backgroundColor: 'red' }}>02</div>
            <span className="team-name">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Anima&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <div className="team-logo" style={{ backgroundImage: "url('/assets/mapcod/anima_black.png')" }}></div>
          </div>
        </div>
        {/* ROUND 2 */}
        <div className="team-section">
          <div className="team-logo" style={{ backgroundImage: "url('/assets/mapcod/lesgaulois_nobg.png')" }}></div>
          <div className="team-info">
            <span className="team-name">&nbsp;&nbsp;Les Gaulois&nbsp;&nbsp;</span>
            <div className="team-finalcount " style={{ backgroundColor: 'green' }}>1&nbsp;1</div>
            <span className="versus">VS</span>
            <div className="team-finalcount " style={{ backgroundColor: 'red' }}>06</div>
            <span className="team-name">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Anima&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <div className="team-logo" style={{ backgroundImage: "url('/assets/mapcod/anima_black.png')" }}></div>
          </div>
        </div>
        <a href="https://youtu.be/QTumlxBGHc0" className="urld">revoir le match</a>
      </article>
    </div>
  );
}

export default Final;
