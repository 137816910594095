import React, { useState, useEffect } from 'react';
import './Match-MakingCOD4.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

function MatchMakingCOD4() {
  const [matches, setMatches] = useState({
    qualifications: [],
    eliminatoires: [],
    finale: []
  });

  const [visibility, setVisibility] = useState({
    qualifications: false,
    eliminatoires: false,
    finale: false
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://dev.borezo.info/mohaa/data.php');
        const data = await response.json();
        setMatches(data.matchs);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); 

    const intervalId = setInterval(fetchData, 3000); 

    return () => {
      clearInterval(intervalId); 
    };
  }, []); 

  const toggleVisibility = (category) => {
    setVisibility(prevState => ({
      ...prevState,
      [category]: !prevState[category]
    }));
  };

  return (
    <section className='section-cod4-matchmaking'>
      {Object.entries(matches).map(([category, categoryMatches]) => (
        <div key={category}>
          <div className={`category-title ${visibility[category] ? 'open' : 'closed'}`} onClick={() => toggleVisibility(category)}>
            <h2>{category.toUpperCase()}</h2> 
            <FontAwesomeIcon icon={visibility[category] ? faChevronDown : faChevronUp} />
          </div>
          {visibility[category] && (
            categoryMatches.map((match, index) => (
              <div className="match-makingcod4" key={index}>
                <div className="team-makingcod4 left-teamcod4">
                  <img src={match.teamleft_picture} alt={match.teamleft_name} className="logo-matchcod4" />
                </div>
                <div className="vs-score-containercod4">
                  <div className="scorecod4 wincod4" style={{ backgroundColor: match.teamleft_color }}>
                    {match.scoreleft}
                  </div>
                  <div className="hudcod4">
                    <p className='mapcod4texte'>{match.map}</p>
                    <p className='vscod4'>VS</p>
                    <a href={match.url} className='redifcod4texte'>REDIFFUSION</a>
                  </div>
                  <div className="scorecod4 losecod4" style={{ backgroundColor: match.teamright_color }}>
                    {match.scoreright}
                  </div>
                </div>
                <div className="team-makingcod4 right-teamcod4">
                  <img src={match.teamright_picture} alt={match.teamright_name} className="logo-matchcod4" />
                </div>
              </div>
            ))
          )}
        </div>
      ))}
    </section>
  );
}

export default MatchMakingCOD4;
