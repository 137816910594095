import React, { useEffect, useState } from "react";
import "./TeamsValorant.scss";
import { useOutletContext } from "react-router-dom";

function TeamsValorant() {
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const { searchQuery } = useOutletContext();

  useEffect(() => {
    // Récupérer les équipes
    fetch("https://api.supra-event.fr/api/tournament/teams")
      .then((response) => response.json())
      .then((data) => setTeams(data))
      .catch((error) => console.error("Erreur lors de la récupération des équipes :", error));

    // Récupérer les joueurs
    fetch("https://api.supra-event.fr/api/tournament/players")
      .then((response) => response.json())
      .then((data) => setPlayers(data))
      .catch((error) => console.error("Erreur lors de la récupération des joueurs :", error));
  }, []);

  // Filtrer les équipes en fonction de la recherche
  const filteredTeams = teams.filter((team) =>
    team.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Trouver les joueurs de l'équipe donnée
  const getPlayersForTeam = (teamId) => {
    return players.filter((player) => player.team === teamId);
  };

  // Calculer les stats combinées pour une équipe
  const calculateTeamStats = (teamPlayers) => {
    const totalKills = teamPlayers.reduce((acc, player) => acc + player.kills, 0);
    const totalDeaths = teamPlayers.reduce((acc, player) => acc + player.deaths, 0);
    const ratio = totalDeaths > 0 ? (totalKills / totalDeaths).toFixed(2) : "Infini"; // Calcul du ratio
    return { totalKills, totalDeaths, ratio };
  };

  return (
    <section className="teams-valorant">
      <h2 className="title-teams-valorant">Teams</h2>
      <div className="teams-list-valorant">
        {filteredTeams.length > 0 ? (
          filteredTeams.map((team) => {
            const teamPlayers = getPlayersForTeam(team.name); // Associe les joueurs à leur équipe
            const teamStats = calculateTeamStats(teamPlayers); // Stats combinées de l'équipe

            return (
              <div key={team._id} className="team-card-valorant">
                <img src={team.logo} alt={`${team.name} logo`} className="team-logo-valorant" />
                <h3 className="team-title-valorant">{team.name}</h3>
                <div className="team-players-valorant">
                  <ul>
                    {teamPlayers.length > 0 ? (
                      teamPlayers.map((player) => (
                        <li key={player._id} className="player-item-valorant">
                          <span>{player.name}</span>{" "}
                          {player.name === team.captain.name ? <strong>Capitaine</strong> : "Joueur"}
                        </li>
                      ))
                    ) : (
                      <p>Aucun joueur trouvé pour cette équipe.</p>
                    )}
                  </ul>
                </div>
                <div className="stats-teams">
                    <div className="stat-item-teams"><h3>{teamStats.totalKills}</h3><p>Kills</p></div>
                    <div className="stat-item-teams"><h3>{teamStats.totalDeaths}</h3><p>Deaths</p></div>
                    <div className="stat-item-teams"><h3>{teamStats.ratio}</h3><p>Ratio K/D</p></div>
                </div>
              </div>
            );
          })
        ) : (
          <p>Aucune équipe trouvée.</p>
        )}
      </div>
    </section>
  );
}

export default TeamsValorant;
