import React, { useEffect, useState } from 'react';
import './AddTeamsCOD4.scss';

function AddTeamsCOD4() {
  const [teamsData, setTeamsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://dev.borezo.info/mohaa/data.php');
        const data = await response.json();
        setTeamsData(data.teams);
      } catch (error) {
        console.error('Error fetching teams data:', error);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className='team-container'>
  {teamsData.map((team, index) => (
    <div className='team-row' key={index}>
      <div className='team-logoembed'>
        <img src={team.picture || 'placeholder.jpg'} alt={`Logo ${team.name}`} />
      </div>
      <div className='team-info'>
        <h2 className='teamwidthcod4'>{team.name}</h2> 
        <p className='player-info'> <strong> {team.leader ? ` ${team.leader } ` : 'No Captain '}</strong>&nbsp;&bull;&nbsp;{team.players.join(' • ')}</p>
      </div>
    </div>
  ))}
</div>

  );
}

export default AddTeamsCOD4;
