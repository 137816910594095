import React from 'react';
import './DemiCOD1.scss';


function DemiFinale() {
  return (
    <div className='container-demi'>
      <h2 className="demi-finale">DEMI FINALE</h2>
      <section className="tournament">

        {/* Match 1 */}
        <article className="match">
          <p className="match-title">MATCH 1</p>
          <div className="team">
            <span className="team-name">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Anima&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <div className="score" style={{ backgroundColor: 'green' }}>1&nbsp;1</div>
            <div className="score" style={{ backgroundColor: 'red' }}>04</div>
            <span className="team-name">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nemesis&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
          <a href="https://youtu.be/7SWKhvhzK-s" className="url">revoir le match</a>
        </article>

        {/* Match 2 */}
        <article className="match">
          <p className="match-title">MATCH 2</p>
          <div className="team">
            <span className="team-name" >&nbsp;&nbsp;Les Gaulois&nbsp;&nbsp;</span>
            <div className="score" style={{ backgroundColor: 'green' }}>1&nbsp;1</div>
            <div className="score" style={{ backgroundColor: 'red' }}>02</div>
            <span className="team-name">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NoT1me&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
          <a href="https://youtu.be/o6H5cO6ucQ4" className="url">revoir le match</a>
        </article>
      </section>
    </div>
  );
}

export default DemiFinale;
