import React from 'react';
import './PointsCOD1.scss';

function PointsCOD1() {
  return (
    // <!-- CLASSEMENT POINT -->
    <section class="average-table-section">
        <h1 class="title-section">CLASSEMENT PRINCIPALE</h1>
        <p class="points-system">Système de points: Victoire = 3 pts • Match nul = 1 pt • Défaite = 0 pt</p>
        <table class="average-table">
          <thead>
            <tr>
              <th class="border-none"></th>
              <th class="border-top">1<sup>ère</sup> PHASE</th>
              <th class="border-top">2<sup>ème</sup> PHASE</th>
              <th class="border-top">3<sup>ème</sup> PHASE</th>
              <th class="border-top">4<sup>ème</sup> PHASE</th>
              <th class="border-top">5<sup>ème</sup> PHASE</th>
              <th class="border-total">TOTAL</th>
            </tr>
          </thead>
          <tbody>
        {/* <!-- CLASSEMENT ÉQUIPE 1 ANIMA --> */}
        <tr>
            <td class="border-left"><img src={require("../../../assets/cod1/player/anima_black.webp")} alt="Équipe Anima" class="team-icon"/></td>
            <td class="average">3</td>
            <td class="average">0</td>
            <td class="average">0</td>
            <td class="average">1</td>
            <td class="average">EX</td>
            <td class="total-average">4</td>
        </tr>
        {/* <!-- CLASSEMENT ÉQUIPE 2 LES GAULOIS --> */}
        <tr>
            <td class="border-left"><img src={require("../../../assets/cod1/player/lesgaulois_nobg.webp")} alt="Équipe Les Gaulois" class="team-icon"/></td>
            <td class="average">3</td>
            <td class="average">3</td>
            <td class="average">3</td>
            <td class="average">EX</td>
            <td class="average">3</td>
            <td class="total-average">12</td>
        </tr>
        {/* <!-- CLASSEMENT ÉQUIPE 3 LES PTITS BITS --> */}
        <tr>
            <td class="border-left"><img src={require("../../../assets/cod1/player/lesptitsbits.webp")} alt="Équipe Les P'tits Bits" class="team-icon"/></td>
            <td class="average">0</td>
            <td class="average">0</td>
            <td class="average">EX</td>
            <td class="average">0</td>
            <td class="average">0</td>
            <td class="total-average">0</td>
        </tr>
        {/* <!-- CLASSEMENT ÉQUIPE 4 NEMESIS --> */}
        <tr>
            <td class="border-left"><img src={require("../../../assets/cod1/player/nemesis.webp")} alt="Équipe Nemesis" class="team-icon"/></td>
            <td class="average">0</td>
            <td class="average">EX</td>
            <td class="average">3</td>
            <td class="average">3</td>
            <td class="average">3</td>
            <td class="total-average">9</td>
        </tr>
        {/* <!-- CLASSEMENT ÉQUIPE 5 NOTIME --> */}
        <tr>
            <td class="border-left"><img src={require("../../../assets/cod1/player/notim_black.webp")} alt="Équipe Notime" class="team-icon"/></td>
            <td class="average">EX</td>
            <td class="average">3</td>
            <td class="average">0</td>
            <td class="average">1</td>
            <td class="average">0</td>
            <td class="total-average">4.</td>
        </tr>
    </tbody>
    </table>
</section>
  );
}

export default PointsCOD1;
