import React from "react";
import './RulesValorant.scss';
import Omen from "../../../assets/valorant/omen.png"

function RulesValorant() {

  return (
    <div className="rules-valorant">
      {/* Section Règlement générale */}
      <section className="rules-rules-section">
            <img src={Omen} alt=""  className="illustration-rules"/>
        <div className="rules-rules-content">
          <h2 className="rules-title">Règlement</h2>
          <p className="rules-text">
            <strong>Bienvenue dans la section Règlement du tournoi</strong> <br />
            Ici, vous trouverez toutes les règles détaillées concernant le déroulement du tournoi <br />
            <br />
            <a href="#inscription" className="rules-click">Inscription :</a> Conditions et étapes pour s'inscrire au tournoi <br />
            <a href="#brackets" className="rules-click">Upper / Lower Brackets :</a> Explications sur le système de double élimination et le déroulement des matchs. <br />
            <a href="#finales" className="rules-click">Grande Finale :</a> Règles spécifiques pour la phase finale du tournoi.<br />
            <a href="#aftermatch" className="rules-click">Après le Match :</a> Ce qu'il faut faire après chaque partie (rapport des scores, feedback, etc...) <br />
            <a href="#driverules" className="rules-click">Règles de Conduite :</a> Les comportements à adopter pour un tournoi respectueux et fair-play <br />
            <br />
            Prenez le temps de bien lire chaque section afin de garantir une expérience <br />
            de jeu fluide et agréable pour tous !
          </p>
          
        </div>
      </section>

      {/* Section Inscription */}
      <section id="inscription" className="rules-section red one">
        <div className="rules-content">
          <h2 className="rules-title red">Inscription</h2>
          <p className="rules-text"> <br />
            • L'équipe doit s'inscrire sur le <a href="https://discord.gghtVnjEqqtE" className="rules-click">Discord</a> avec la commande /inscription <strong>avant le 25/11/2024</strong> <br /><br />
            • Frais d'inscription par équipe de <strong>40€ minimum</strong> via Paypal : <a href="https://paypal.me/pixetmotion" className="rules-click">paypal.me/pixetmotion</a> <br /><br />
            • Dans le modal d'inscription vous devrez entrer
                <ul>
                    <li>Nom de l'équipe</li>
                    <li>Nom du capitaine</li>
                    <li>Liste de 4 joueurs (Inscrivez le compte principal)</li>
                </ul>
                <br />
            • Chaque équipe ne doit pas dépasser les critères suivants 
                <ul>
                    <li>1 Ascendant / Diamant Maximum</li>
                    <li>2 Platines Maximum</li>
                    <li>Gold / Silver (Pas de restriction)</li>
                    <li>Les autres ranks ne sont pas acceptés</li>
                </ul>
                <br />
            • Les comptes doivent impérativement être reliés à <a href="https://tracker.gg/valorant" className="rules-click">Tracker.gg</a> <br /><br />
            • Les comptes principaux doivent avoir joué un minimum de 20 parties en ranked pour que leurs inscriptions soit validées <br />
          </p>
        </div>
      </section>

      {/* Section Brackets */}
      <section id="brackets" className="rules-section black one">
        <div className="rules-content">
          <h2 className="rules-title black">Upper / Lower Brackets</h2>
          <p className="rules-text"> <br />
            Le format Upper / Lower Bracket, également connu sous le nom de <strong>Double Élimination</strong> <br />
            est une structure de tournoi où chaque équipe a une seconde chance après une première défaite. <br />
            <br />
            <strong>Upper Bracket (Tableau des Gagnants)</strong> <br />
            • <strong>Initiale :</strong> Toutes les équipes commencent dans l'Upper Bracket <br /><br />
            • <strong>Progression :</strong> Les équipes gagnantes continuent de progresser dans l'Upper Bracket jusqu'à la finale de ce tableau <br /><br />
            • <strong>Défaite : </strong> Lorsqu'une équipe perd un match de l'Upper Bracket, elle est reléguée dans le Lower Bracket <br />
            <br />
            <strong>Lower Bracket (Tableau des Perdants)</strong> <br />
            • <strong>Entrée : </strong> Les équipes qui perdent dans l'Upper Bracket rejoignent le Lower Bracket <br /><br />
            • <strong>Élimination : </strong> Une défaite dans le lower bracket entraîne l'élimination définitive de l'équipe du tournoi <br /><br />
            • <strong>Progression : </strong> Les équipes continuent de s'affronter dans le Lower Bracket. <br /><br />
            Le vainqueur du Lower Bracket affrontera ensuite le gagnant de la finale de l'Upper Bracket dans la Grande Finale. <br />

          </p>
        </div>
      </section>

      {/* Section Finales */}
      <section id="finales" className="rules-section red two">
        <div className="rules-content">
          <h2 className="rules-title red">Grande Finale</h2>
          <p className="rules-text"> <br />
          • <strong>Composition : </strong> La Grande Finale oppose le gagnant de l'Upper Bracket et le gagnant du Lower Bracket <br /><br />
          • <strong>Avantage Upper Bracket :</strong> Le gagnant de l'Upper Bracket bénéficie d'un avantage <br />
          (Le gagnant commencera le BO5 avec 1 set d'avance), le gagnant du Lower Bracket doit en gagner trois pour remporter le tournoi <br />
          <br /><br />
          <strong>Ban + Sides</strong> <br />
          • Les 2 Capitaines d'équipe bannissent une map chacun sur discord, un tirage aléatoire hormis c'est 2 maps sera fait sur le live de <a href="https://twitch.tv/pixetmotion" className="rules-click">Pix&Motion</a> <br /><br />
          • Pour le choix du side un pile ou face sera alors effectué (L'équipe gagnante du side aura alors le choix du side) <br />
          <br /><br />
          <strong>Pick and Ban (BO3 / BO5)</strong> <br />
          • <strong>Choix des maps :</strong> Le processus de choix de maps suit un format de Pick and Ban. <br /><br />
          • <strong>Cela se déroule comme suit :</strong>
          <ul>
            <li>Tirage au sort (Pile ou Face) déterminée quelle équipe commence</li>
            <li>L'équipe A (déterminer par le tirage au sort) bannit une map</li>
            <li>L'équipe B bannit une map</li>
            <li>L'équipe A choisit une map pou le premier match (inversement pour la 2ème map)</li>
          </ul>
          <br />
          Pour la première map, l'équipe qui n'a pas choisi la map décide du côté (Attaque ou Défense) <br />
          sur lequel elle commence. Pour la seconde map, l'autre équipe décide du côté. Si une troisième <br />
          map est nécessaire, un nouveau tirage au sort détermine l'équipe qui choisira le côté

          </p>
        </div>
      </section>

      {/* Section Après le Match */}
      <section id="aftermatch" className="rules-section black two">
        <div className="rules-content">
          <h2 className="rules-title black">Après le Match</h2>
          <p className="rules-text"> <br />
            • Le classement sera mis à jour régulièrement pour refléter les performances des équipes<br /><br />
            • En cas de problème technique ou de déconnexion, le staff se réserve le droit de décider de la reprise ou de la poursuite du match selon la situation<br /><br />
            • Les 2 capitaines d'équipes du prochain match bannissent une map chacun sur discord, un tirage aléatoire hormis c'est 2 maps sera fait<br /><br />
            • Les équipes peuvent fournir un feedback sur le déroulement du match ou déposer une réclamation en cas de litige<br /><br />
            Les réclamations doivent être faites dans les 10 min suivant la fin du match<br /><br />
            <br />
          </p>
        </div>
      </section>

      {/* Section Règles de Conduite */}
      <section id="driverules" className="rules-section red three">
        <div className="rules-content">
          <h2 className="rules-title red">Règles de Conduite</h2>
          <p className="rules-text"> <br />
          • Les joueurs doivent respecter leurs coéquipiers, les adversaires et l'équipe des pixionautes.<br /><br />
          • Aucun comportement toxique, insultant ou discriminatoire ne sera toléré.<br /><br />
          • Les joueurs doivent être présents et prêts à jouer à l'heure<br /><br />
          • Les retards non justifiés pourront entraîner une disqualification<br /><br />
          • L'équipe des pixionautes se réserve le droit de modifier le règlement à tout moment pour assurer le bon déroulement du tournoi<br />
          Toute modification sera annoncée sur le serveur discord.<br /><br />
          • En s'inscrivant au tournoi, les joueurs acceptent de se conformer à l'ensemble des règles énoncées ci-dessus<br /><br />
          • Pour toute question ou clarification, n'hésitez pas à nous contacter sur le discord via le système de tickets<br /><br />
            <br />

            Cordialement le Staff des pixionautes <br />
            <strong>Pix, HellFire, Miss, Tassimo, Paleolouis</strong>
          </p>
        </div>
      </section>
    </div>
  );
}

export default RulesValorant;
