import React from 'react';
import './EliminatoireCOD1.scss';
import DemiCOD1 from "../../../components/COD1-Components/DemiCOD1/DemiCOD1";
import FinalCOD1 from "../../../components/COD1-Components/FinalCOD1/FinalCOD1"

function EliminatoireCOD1() {
  return (
    <section className='cod1-eliminatoire'>
        <img src={require("../../../assets/cod1/Background/eliminatoire_banner.webp")} alt="Infos Serveur" className='banner'/>
        <DemiCOD1 />
        <FinalCOD1 />
    </section>
  );
}

export default EliminatoireCOD1
