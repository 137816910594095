import React from 'react';
import './AccueilValorant.scss';
import { NavLink } from 'react-router-dom';

function AccueilValorant() {
  return (
    <div className="accueil-valorant">
      {/* Vidéo YouTube en plein écran */}
      <div className="video-container">
      <iframe
          src="https://www.youtube.com/embed/btpWg1gDXIE?autoplay=1&mute=1&loop=1&playlist=btpWg1gDXIE"
          title="Valorant Trailer"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />

      </div>

      {/* Section "Les Agents" */}
      <section className="accueil-section agents">
        <div className="accueil-content right">
          <div className="accueil-text right">
            <h2 className='accueil-title right'>Les Agents</h2>
            <p className='accueil-text-p'>
                <strong>Retrouvez ici tous les agents qui participent à notre tournoi Valorant</strong><br />
                Les agents sont prêts à entrer en action et à montrer leurs talents dans cette compétition intense !<br />
                Que vous soyez un stratège expérimenté ou un novice plein d'enthousiasme, chacun a une<br />
                chance de briller et de contribuer à des moments épiques <br />
                <br />
                Qui dominera l'arène et remportera la victoire ? Suivez nos agents tout au long du tournoi pour le découvrir
            </p>
            <NavLink to="/tournament/valorant/agents" className="accueil-button right">
              Voir les agents
            </NavLink>
          </div>
        </div>
      </section>

      {/* Section "Les teams_valo" */}
      <section className='accueil-section teams_valo'>
        <div className='accueil-content left'>
            <div className='accueil-text left'>
                <h2 className='accueil-title left'>Les teams</h2>
                <p className='accueil-text-p'>
                    <strong>Retrouvez ici toutes les Teams qui participent à notre tournoi Valorant !</strong> <br />
                    Les Teams sont prêtes à se battre pour la gloire, à faire preuve de stratégie et à donner le meilleur <br />
                    d'elles-mêmes dans cette compétition acharnée ! Qu'il s'agisse de vétérans bien rodés ou de <br />
                    nouvelles alliances prometteuses, chaque équipe aura l'opportunité de marquer l'histoire et de créer <br />
                    des moments mémorables. <br />
                    <br />
                    Quelle team saura s'imposer et décrocher la victoire ? Suivez leur progression tout au long du tournoi pour le découvrir
                </p>
                <NavLink to="/tournament/valorant/teams_valo" className="accueil-button left">
                    Voir les teams
                </NavLink>
            </div>
        </div>
      </section>

      {/* Section "Brackets" */}
      <section className='accueil-section brackets'>
        <div className='accueil-content right'>
            <div className='accueil-text right'>
                <h2 className='accueil-title right'>Brackets</h2>
                <p className='accueil-text-p'>
                    <strong>Suivez l'évolution des équipes dans les Upper et Lower Brackets</strong> <br />
                    Dans cette phase du tournoi, les équipes des <strong>Upper Brackets</strong> se battent pour rester en tête tandis <br />
                    que celles des <strong>Lower Brackets</strong> luttent pour leur survie ! Chaque match est décisif, avec d'un côté <br />
                    la route directe vers la victoire, et de l'autre, une chance de rebondir et de revenir plus fort. <br />
                    <br />
                    Quelle équipe restera invaincue et qui réussira à remonter du bas pour décrocher la victoire ultime ? <br />
                    Les affrontements s'annoncent intenses 
                </p>
                <NavLink to="/tournament/valorant/brackets" className="accueil-button right">
                    Voir Les Brackets
                </NavLink>
            </div>
        </div>
      </section>

      {/* Section "LeaderBoards" */}
      <section className='accueil-section leaderboards'>
        <div className='accueil-content left'>
            <div className='accueil-text left'>
                <h2 className='accueil-title left'>LeaderBoards</h2>
                <p className='accueil-text-p'>
                    <strong>Consultez les statistiques des équipes et des joueurs sur le leaderboard</strong> <br />
                    Le leaderboard présente les meilleurs performance du tournoi, que ce soit au niveau des équipes <br />
                    ou des joueurs. Suivez de près les statistiques clés: Kills, Death, ratios k/D, et bien plus encore. <br />
                    Chaque point compte, et les classements évoluent à chaque match. <br />
                    <br />
                    Qui sera le joueur avec le meilleur score, et quelle équipe dominera les statistiques globales ? <br />
                    Restez connectés pour suivre en direct l'evolution des performances !  
                </p>
                <NavLink to="/tournament/valorant/leaderboard" className="accueil-button left">
                    Voir le Leaderboards
                </NavLink>
            </div>

        </div>

      </section>
    </div>
  );
}

export default AccueilValorant;