import React from 'react';
import './HallOfFame.scss';

// URLs des tournois
const valorantTournamentUrl = "/tournament/valorant/accueil";
const cod4TournamentUrl = "/tournament/cod4/caserne";
const cod1TournamentUrl = "/tournament/cod1/teams";

function HallOfFame() {
  return (
    <section className="halloffame">
      {/* Section Valorant */}
      <div className="halloffame-section valorant">
        <a href={valorantTournamentUrl}>
          <img
            src={require('../../assets/halloffame/suke-x.jpg')}
            alt="Valorant Tournament"
            className="halloffame-image"
          />
          <div className="halloffame-overlay">
            <h2>Valorant</h2>
            <p>Plongez dans l'univers compétitif de Valorant ! Découvrez les règles, rencontrez les équipes et consultez les statistiques des joueurs.</p>
          </div>
        </a>
      </div>

      {/* Section Call of Duty 4 */}
      <div className="halloffame-section cod4">
        <a href={cod4TournamentUrl}>
          <img
            src={require('../../assets/halloffame/cod4.jpg')}
            alt="Call of Duty 4 Tournament"
            className="halloffame-image"
          />
          <div className="halloffame-overlay">
            <h2>Call of Duty 4</h2>
            <p>Parcourez le tournoi Call of Duty 4 ! Explorez les stratégies des meilleurs joueurs, examinez les équipes en compétition et consultez le règlement complet.</p>
          </div>
        </a>
      </div>

      {/* Section Call of Duty 1 */}
      <div className="halloffame-section cod1">
        <a href={cod1TournamentUrl}>
          <img
            src={require('../../assets/halloffame/cod1.jpg')}
            alt="Call of Duty 1 Tournament"
            className="halloffame-image"
          />
          <div className="halloffame-overlay">
            <h2>Call of Duty 1</h2>
            <p>Revivez la nostalgie du premier Call of Duty ! Accédez aux règles, découvrez les vétérans qui s'affrontent et consultez les statistiques pour ne rien manquer.</p>
          </div>
        </a>
      </div>
    </section>  
  );
}

export default HallOfFame;
