import React from 'react';
import './GoalAverageCOD1.scss';

function GoalAverageCOD1() {
  return (
    <section className="average-table-section">
      <h1 className="title-section">GOAL AVERAGE</h1>
      <table className="average-table">
        <thead>
          <tr>
            <th className="border-none"></th>
            <th className="border-top">1<sup>ère</sup> PHASE</th>
            <th className="border-top">2<sup>ème</sup> PHASE</th>
            <th className="border-top">3<sup>ème</sup> PHASE</th>
            <th className="border-top">4<sup>ème</sup> PHASE</th>
            <th className="border-top">5<sup>ème</sup> PHASE</th>
            <th className="border-total">TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {/* CLASSEMENT ÉQUIPE 1 ANIMA */}
          <tr>
            <td className="border-left"><img src={require("../../../assets/cod1/player/anima_black.webp")} alt="Équipe Anima" className="team-icon"/></td>
            <td className="average">11</td>
            <td className="average">0</td>
            <td className="average">7</td>
            <td className="average">10</td>
            <td className="average">EX</td>
            <td className="total-average">28</td>
          </tr>
          {/* CLASSEMENT ÉQUIPE 2 LES GAULOIS */}
          <tr>
            <td className="border-left"><img src={require("../../../assets/cod1/player/lesgaulois_nobg.webp")} alt="Équipe Les Gaulois" className="team-icon"/></td>
            <td className="average">11</td>
            <td className="average">11</td>
            <td className="average">11</td>
            <td className="average">EX</td>
            <td className="average">11</td>
            <td className="total-average">44</td>
          </tr>
          {/* CLASSEMENT ÉQUIPE 3 LES PTITS BITS */}
          <tr>
            <td className="border-left"><img src={require("../../../assets/cod1/player/lesptitsbits.webp")} alt="Équipe Les P'tits Bits" className="team-icon"/></td>
            <td className="average">2</td>
            <td className="average">7</td>
            <td className="average">EX</td>
            <td className="average">0</td>
            <td className="average">1</td>
            <td className="total-average">10</td>
          </tr>
          {/* CLASSEMENT ÉQUIPE 4 NEMESIS */}
          <tr>
            <td className="border-left"><img src={require("../../../assets/cod1/player/nemesis.webp")} alt="Équipe Nemesis" className="team-icon"/></td>
            <td className="average">2</td>
            <td className="average">EX</td>
            <td className="average">11</td>
            <td className="average">11</td>
            <td className="average">11</td>
            <td className="total-average">35</td>
          </tr>
          {/* CLASSEMENT ÉQUIPE 5 NOTIME */}
          <tr>
            <td className="border-left"><img src={require("../../../assets/cod1/player/notim_black.webp")} alt="Équipe N0t1me" className="team-icon"/></td>
            <td className="average">EX</td>
            <td className="average">11</td>
            <td className="average">6</td>
            <td className="average">10</td>
            <td className="average">5</td>
            <td className="total-average">32</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}

export default GoalAverageCOD1;
