import React from 'react';
import './MainCOD4.scss';

function MainMOHAA({ children }) {
  return (
    
    <div className='main-mohaa'>
      {children}
    </div>
  )
}

export default MainMOHAA