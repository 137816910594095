import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import './NavBarValorant.scss';
import SearchBarValorant from "../SearchBarValorant/SearchBarValorant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import LogoPixTournament from "../../../assets/logo/log_pixtournament.png";

function NavBarValorant({ onSearch }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false); // État pour le menu
    const location = useLocation();

    const showSearchBar = location.pathname === "/tournament/valorant/agents" || location.pathname === "/tournament/valorant/teams";

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); // Basculer l'état du menu
    };

    return (
        <nav className="navbarvalorant">
            <NavLink to="/halloffame"><img src={LogoPixTournament} alt="Logo Pix Tournament" className="logo_valorant" /> </NavLink>
            <div className="menu-icon" onClick={toggleMenu}>
                <FontAwesomeIcon icon={faBars} />
            </div>
            <div className={`nav-links ${isMenuOpen ? "open" : ""}`}>
                <NavLink to="/tournament/valorant/accueil" className={({ isActive }) => isActive ? "active-link" : ""}>ACCUEIL</NavLink>
                <NavLink to="/tournament/valorant/rules" className={({ isActive }) => isActive ? "active-link" : ""}>RÈGLEMENT</NavLink>
                <NavLink to="/tournament/valorant/agents" className={({ isActive }) => isActive ? "active-link" : ""}>AGENTS</NavLink>
                <NavLink to="/tournament/valorant/teams" className={({ isActive }) => isActive ? "active-link" : ""}>TEAMS</NavLink>
                <NavLink to="/tournament/valorant/brackets" className={({ isActive }) => isActive ? "active-link" : ""}>BRACKETS</NavLink>
                <NavLink to="/tournament/valorant/leaderboard" className={({ isActive }) => isActive ? "active-link" : ""}>LEADERBOARDS</NavLink>
                {showSearchBar && <SearchBarValorant onSearch={onSearch} />} 
            </div>
        </nav>
    );
}

export default NavBarValorant;
