import React from 'react';
import './footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faDiscord, faTiktok, faInstagram, faTwitter, faYoutube, faTwitch} from '@fortawesome/free-brands-svg-icons';
function Footer() {
  return (
    <footer className="halloffame-footer">
      <div className="halloffame-footer-left">
        <p>DESIGN PAR PIXETMOTION - DÉVELOPPÉ PAR HELLFIRE © 2024</p>
      </div>

      <div className="halloffame-footer-center">
        <a href="https://twitch.tv/pixetmotion" className="twitch-link">
        <FontAwesomeIcon icon={faTwitch}/>/pixetmotion
        </a>
      </div>

      <div className="halloffame-footer-social-media">
        <p>VIENS FOLLOW NOS RÉSEAUX</p>
        <a href="https://discord.gg/htVnjEqqtE">
            <FontAwesomeIcon icon={faDiscord}/>
        </a>
        <a href="https://www.instagram.com/twitchpixetmotion/">
        <FontAwesomeIcon icon={faInstagram}/>
        </a>
        <a href="https://www.youtube.com/@pixetmotiongaming">
        <FontAwesomeIcon icon={faYoutube}/>
        </a>
        <a href="https://twitter.com/pixetmotion">
        <FontAwesomeIcon icon={faTwitter}/>
        </a>
        <a href="https://www.tiktok.com/@pixetmotion">
        <FontAwesomeIcon icon={faTiktok}/>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
