import React from 'react';
import './FooterValorant.scss';
import twitchIcon from '../../../assets/valorant/icons/icon_twitch.png';
import discordIcon from '../../../assets/valorant/icons/icon_discord.png';
import instaIcon from '../../../assets/valorant/icons/icon_insta.png';
import youtubeIcon from '../../../assets/valorant/icons/icon_youtube.png';
import twitterIcon from '../../../assets/valorant/icons/icon-twitter.png';
import tiktokIcon from '../../../assets/valorant/icons/icon_tiktok.png';

const socialLinks = [
  { href: 'https://discord.gg/htVnjEqqtE', icon: discordIcon, alt: 'Discord' },
  { href: 'https://www.instagram.com/twitchpixetmotion/', icon: instaIcon, alt: 'Instagram' },
  { href: 'https://www.youtube.com/@pixetmotiongaming', icon: youtubeIcon, alt: 'Youtube' },
  { href: 'https://twitter.com/pixetmotion', icon: twitterIcon, alt: 'Twitter' },
  { href: 'https://www.tiktok.com/@pixetmotion', icon: tiktokIcon, alt: 'Tiktok' },
];

function FooterValorant() {
  return (
    <footer className="footer-valorant">
      <span className="footer-text">DESIGN PAR HELLFIRE44 | DÉVELOPPÉ PAR HELLFIRE</span>
      <a href="https://twitch.tv/pixetmotion" className="footer-twitch-link">
        <img src={twitchIcon} alt="Twitch" className="footer-twitch-icon" /> TWITCH.TV/PIXETMOTION
      </a>
      <div className="footer-social-icons">
        {socialLinks.map(({ href, icon, alt }) => (
          <a key={alt} href={href} className="footer-icon-link">
            <img src={icon} alt={alt} className="footer-icon" />
          </a>
        ))}
      </div>
    </footer>
  );
}

export default FooterValorant;
