import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../layout/Default/Header/Header';
import Main from '../../layout/Default/Main/Main';
import Footer from "../../layout/Default/Footer/Footer"
import './errorPage.scss'

export function ErrorPage() {
  return (
    <>
      <Header />
      <Main >
            <div className="error-page">
              <div>
                  <h1>ERROR 404</h1>
                  <p>La page n'existe pas</p>
              </div>
                  <Link to="/">Retourner sur la page d'accueil</Link>
            </div>

      </Main>
      <Footer />
      
    </>
  )
}