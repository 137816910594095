import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import data from "./data_bracket.json"; 
import "./BracketsValorant.scss"; 
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

export default function App() {
  const [currentBracket, setCurrentBracket] = useState("upper_bracket"); 
  const brackets = ["upper_bracket", "lower_bracket", "grand_final"];
  const bracketTitles = {
    upper_bracket: "Upper Bracket",
    lower_bracket: "Lower Bracket",
    grand_final: "Grande Finale"
  };

  const handleNextBracket = () => {
    const currentIndex = brackets.indexOf(currentBracket);
    const nextIndex = (currentIndex + 1) % brackets.length;
    setCurrentBracket(brackets[nextIndex]);
  };

  const handlePreviousBracket = () => {
    const currentIndex = brackets.indexOf(currentBracket);
    const prevIndex = (currentIndex - 1 + brackets.length) % brackets.length;
    setCurrentBracket(brackets[prevIndex]);
  };

  const currentData = data[currentBracket];

  // Function to format title
  const formatTitle = (title) => {
    return title.toLowerCase().replace(/\s+/g, '_'); // Format title
  };

  return (
    <div className="bracket-container">
      <h1 className="title-brackets">BRACKETS</h1>
      <div className={`bracket-header ${currentBracket}`}>
        <FontAwesomeIcon icon={faChevronLeft} onClick={handlePreviousBracket} />
        <h1>{bracketTitles[currentBracket]}</h1>
        <FontAwesomeIcon icon={faChevronRight} onClick={handleNextBracket} />
      </div>

      <div className="brackets" style={{ position: 'relative' }}>
        {currentData.rounds.map((round, roundIndex) => (
          <div className={`column_${formatTitle(bracketTitles[currentBracket])}_${roundIndex + 1}`} key={roundIndex}>
            <h2>{round.round_name}</h2>
            {round.matches.map((match, matchIndex) => (
              <div className={`match match_${matchIndex + 1}`} key={matchIndex}>
                <div className={`team_left ${match.winner === "left" ? "win" : "loose"}`}>
                  <h2 className="team_left_name">{match.team_left_name}</h2>
                  <p className="team_left_score">{match.team_left_score}</p>
                </div>
                <span className="space-between-valo"></span>
                <div className={`team_right ${match.winner === "right" ? "win" : "loose"}`}>
                  <h2 className="team_right_name">{match.team_right_name}</h2>
                  <p className="team_right_score">{match.team_right_score}</p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
