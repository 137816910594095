import React, { useEffect, useState } from "react";
import "./LeaderBoardValorant.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGun, faPercent, faSkull, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";

function LeaderBoardValorant() {
  const [players, setPlayers] = useState([]);
  const [topPlayer, setTopPlayer] = useState(null);
  const [teamsWithStats, setTeamsWithStats] = useState([]);
  const [showAllPlayers, setShowAllPlayers] = useState(false);
  const [showAllTeams, setShowAllTeams] = useState(false);
  
  // Configuration de tri pour les joueurs
  const [playerSortConfig, setPlayerSortConfig] = useState({ key: "kills", direction: "descending" });
  
  // Configuration de tri pour les équipes
  const [teamSortConfig, setTeamSortConfig] = useState({ key: "kills", direction: "descending" });


    // Récupérer les données des joueurs depuis l'API
    useEffect(() => {
        fetch("https://api.supra-event.fr/api/tournament/players")
        .then((response) => response.json())
        .then((data) => {
            setPlayers(data);
            // Trouver le meilleur joueur selon le ratio K/D
            const bestPlayer = data.reduce(
            (prev, curr) => (curr.deaths > 0 && (curr.kills / curr.deaths) > (prev.kills / prev.deaths) ? curr : prev),
            data[0]
            );
            setTopPlayer(bestPlayer);
        });
    }, []);
  

  // Récupérer les données des équipes et calculer les kills/deaths totaux
  useEffect(() => {
    fetch("https://api.supra-event.fr/api/tournament/teams")
      .then((response) => response.json())
      .then((teamsData) => {
        const updatedTeams = teamsData.map((team) => {
          const teamPlayers = players.filter((player) => player.team === team.name);
          const totalKills = teamPlayers.reduce((acc, player) => acc + player.kills, 0);
          const totalDeaths = teamPlayers.reduce((acc, player) => acc + player.deaths, 0);
          return {
            ...team,
            kills: totalKills,
            deaths: totalDeaths,
            kdRatio: totalDeaths > 0 ? (totalKills / totalDeaths).toFixed(2) : "N/A",
          };
        });

        setTeamsWithStats(updatedTeams);
      });
  }, [players]);

  // Fonction de tri générique pour les joueurs
  const handlePlayerSort = (key) => {
    const direction = playerSortConfig.key === key && playerSortConfig.direction === "ascending" ? "descending" : "ascending";
    setPlayerSortConfig({ key, direction });
  };

  // Fonction de tri générique pour les équipes
  const handleTeamSort = (key) => {
    const direction = teamSortConfig.key === key && teamSortConfig.direction === "ascending" ? "descending" : "ascending";
    setTeamSortConfig({ key, direction });
  };

  const sortedData = (data, key, direction) => {
    if (!key) return data;
    return [...data].sort((a, b) => {
      if (key === "kdRatio") {
        const ratioA = a.deaths > 0 ? (a.kills / a.deaths) : Infinity; // Gérer les cas où deaths est 0
        const ratioB = b.deaths > 0 ? (b.kills / b.deaths) : Infinity; // Gérer les cas où deaths est 0
        return direction === "ascending" ? ratioA - ratioB : ratioB - ratioA;
      } else {
        return direction === "ascending" ? a[key] - b[key] : b[key] - a[key];
      }
    });
  };

  // Gestion du tri des joueurs
  const sortedPlayers = sortedData(players, playerSortConfig.key, playerSortConfig.direction);

  // Gestion du tri des équipes
  const sortedTeams = sortedData(teamsWithStats, teamSortConfig.key, teamSortConfig.direction);

  // Gestion de l'affichage des joueurs et équipes (10 par défaut)
  const playersToShow = showAllPlayers ? sortedPlayers.length : 10;
  const teamsToShow = showAllTeams ? sortedTeams.length : 3;

  return (
    <div className="leaderboard-valorant">
      <h1 className="title-leaderboard">LeaderBoards</h1>
      {/* MVPs */}
      <div className="mvp-section">
        <div className="mvp-cards">
          {topPlayer && (
            <MVPCard
              title="MVP - JOUEUR" // Titre pour les joueurs
              name={topPlayer.name}
              team={topPlayer.team}
              image={topPlayer.profileImage}
              kills={topPlayer.kills}
              deaths={topPlayer.deaths}
              ratio={(topPlayer.kills / topPlayer.deaths).toFixed(2)}
              type="joueur" // Indicateur pour le type
            />
          )}
          {teamsWithStats.length > 0 && (
            <MVPCard
              title="MVP - ÉQUIPE" // Titre pour les équipes
              name={teamsWithStats[0].name}
              image={teamsWithStats[0].logo}
              kills={teamsWithStats[0].kills}
              deaths={teamsWithStats[0].deaths}
              ratio={teamsWithStats[0].kdRatio}
              type="equipe" // Indicateur pour le type
            />
          )}
        </div>
      </div>

      {/* Table Leaderboard Joueurs */}
      <table className="leaderboard-table">
  <caption>Leaderboard Joueurs</caption>
  <thead>
    <tr>
      <th>Rang</th>
      <th onClick={() => handlePlayerSort("name")}>
        <span className="text">Nom du Joueur</span>
        <span className="icon"><FontAwesomeIcon icon={faUser} /></span>
      </th>
      <th onClick={() => handlePlayerSort("kills")}>
        <span className="text">Kills</span>
        <span className="icon"><FontAwesomeIcon icon={faGun} /></span>
        {playerSortConfig.key === "kills" ? (playerSortConfig.direction === "ascending" ? "↑" : "↓") : ""}
      </th>
      <th onClick={() => handlePlayerSort("deaths")}>
        <span className="text">Deaths</span>
        <span className="icon"><FontAwesomeIcon icon={faSkull} /></span>
        {playerSortConfig.key === "deaths" ? (playerSortConfig.direction === "ascending" ? "↑" : "↓") : ""}
      </th>
      <th onClick={() => handlePlayerSort("kdRatio")}>
        <span className="text">Ratio K/D</span>
        <span className="icon"><FontAwesomeIcon icon={faPercent} /></span>
        {playerSortConfig.key === "kdRatio" ? (playerSortConfig.direction === "ascending" ? "↑" : "↓") : ""}
      </th>
      <th className="team-column">Équipe</th>
    </tr>
  </thead>
  <tbody>
    {sortedPlayers.slice(0, playersToShow).map((player, index) => (
      <tr key={player._id}>
        <td>{index + 1}</td>
        <td>{player.name}</td>
        <td>{player.kills}</td>
        <td>{player.deaths}</td>
        <td>{(player.kills / player.deaths).toFixed(2)}</td>
        <td className="team-column">{player.team}</td>
      </tr>
    ))}
  </tbody>
</table>

      <button onClick={() => setShowAllPlayers(!showAllPlayers)} className="button_more_leaderboard_valo">
        {showAllPlayers ? "Afficher moins" : "Afficher tout"}
      </button>

      {/* Table Leaderboard Équipes */}
      <table className="leaderboard-table">
  <caption>Leaderboard Équipes</caption>

  <thead>
    <tr>
      <th>Rang</th>
      <th onClick={() => handleTeamSort("name")}>
        <span className="text">Nom de l'Équipe</span>
        <span className="icon"><FontAwesomeIcon icon={faUsers} /></span>
      </th>
      <th onClick={() => handleTeamSort("kills")}>
        <span className="text">Kills Totaux</span>
        <span className="icon"><FontAwesomeIcon icon={faGun} /></span>
        {teamSortConfig.key === "kills" ? (teamSortConfig.direction === "ascending" ? "↑" : "↓") : ""}
      </th>
      <th onClick={() => handleTeamSort("deaths")}>
        <span className="text">Deaths Totaux</span>
        <span className="icon"><FontAwesomeIcon icon={faSkull} /></span>
        {teamSortConfig.key === "deaths" ? (teamSortConfig.direction === "ascending" ? "↑" : "↓") : ""}
      </th>
      <th onClick={() => handleTeamSort("kdRatio")}>
        <span className="text">Ratio K/D</span>
        <span className="icon"><FontAwesomeIcon icon={faPercent} /></span>
        {teamSortConfig.key === "kdRatio" ? (teamSortConfig.direction === "ascending" ? "↑" : "↓") : ""}
      </th>
    </tr>
  </thead>
  <tbody>
    {sortedTeams.slice(0, teamsToShow).map((team, index) => (
      <tr key={team._id}>
        <td>{index + 1}</td>
        <td>{team.name}</td>
        <td>{team.kills}</td>
        <td>{team.deaths}</td>
        <td>{team.kdRatio}</td>
      </tr>
    ))}
  </tbody>
</table>

      <button onClick={() => setShowAllTeams(!showAllTeams)} className="button_more_leaderboard_valo">
        {showAllTeams ? "Afficher moins" : "Afficher tout"}
      </button>
    </div>
  );
}

// Composant pour les cartes MVP
function MVPCard({ title, name, image, kills, deaths, ratio, type }) {
  return (
    <div className="mvp-card">
      <h3 className={`mvp-title ${type}`}>{title}</h3>
      <img src={image} alt={name} className={`mvp-image ${type}`} />
      <p className="mvp-name">{name}</p>
      <div className={`mvp-stats ${type}`}>
        <div className="mvp-stat-item">
          <p>{kills}<br /><FontAwesomeIcon icon={faGun}/></p>
        </div>
        <div className="mvp-stat-item">
          <p>{deaths}<br /><FontAwesomeIcon icon={faSkull}/></p>
        </div>
        <div className="mvp-stat-item">
          <p>{ratio}<br /><FontAwesomeIcon icon={faPercent}/></p>
        </div>
      </div>
    </div>
  );
}

export default LeaderBoardValorant;
