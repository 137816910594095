import React, {useState} from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";
import '../style/valorant.scss';  // Assure-toi que le chemin est correct


// Layout Valorant
import { Header, Footer, Main } from "../layout/Default";
import { HeaderCOD4, MainCOD4, FooterCOD4 } from "../layout/COD4"
import { HeaderCOD1, MainCOD1, FooterCOD1 } from "../layout/COD1";
import { HeaderValorant, MainValorant, FooterValorant } from "../layout/VALO";

// PAGE HALL OF FAME
import HallOfFame from "../pages/HallOfFame/HallOfFame";
import { ErrorPage } from "../pages/ErrorPage/ErrorPage";

// PAGE COD1
import TeamsCOD1 from "../pages/COD1-Pages/TeamsCOD1/TeamsCOD1";
import MatchCOD1 from "../pages/COD1-Pages/MatchCOD1/MatchCOD1";
import ClassementCOD1 from "../pages/COD1-Pages/ClassementCOD1/ClassementCOD1";
import EliminatoireCOD1 from "../pages/COD1-Pages/EliminatoireCOD1/EliminatoireCOD1";

// PAGE COD4
import CaserneCOD4 from "../pages/COD4-Pages/CaserneCOD4/CaserneCOD4";
import RulesCOD4 from "../pages/COD4-Pages/RulesCOD4/RulesCOD4";
import RecruesCOD4 from "../pages/COD4-Pages/RecruesCOD4/RecruesCOD4";
import TeamsCOD4 from "../pages/COD4-Pages/TeamsCOD4/TeamsCOD4";
import MatchCOD4 from "../pages/COD4-Pages/MatchCOD4/MatchCOD4";
import ClassementCOD4 from "../pages/COD4-Pages/ClassementCOD4/ClassementCOD4";
import TirageCOD4 from "../pages/COD4-Pages/TirageCOD4/TirageCOD4";
import TirageCOD44 from "../pages/COD4-Pages/TirageCOD44/TirageCOD44";
import TirageMapCOD4 from "../pages/COD4-Pages/TirageMapCOD4/TirageMapCOD4";
import ImagesCOD4 from "../pages/COD4-Pages/ImagesCOD4/ImagesCOD4";

// PAGE VALORANT
import AccueilValorant from "../pages/VALO-Pages/AccueilValorant/AccueilValorant";
import RulesValorant from "../pages/VALO-Pages/RulesValorant/RulesValorant";
import AgentsValorant from "../pages/VALO-Pages/AgentsValorant/AgentsValorant";
import TeamsValorant from "../pages/VALO-Pages/TeamsValorant/TeamsValorant";
import BracketsValorant from "../pages/VALO-Pages/BracketsValorant/BracketsValorant";
import LeaderBoardValorant from "../pages/VALO-Pages/LeaderBoardValorant/LeaderBoardValorant";

const HeaderFooterLayout = () => {
      return <>
            <Header />
            <Main>
                  <Outlet />
            </Main>
            <Footer />
      </>
};

const HeaderFooterCod1Layout = () => {
      return <>
            <HeaderCOD1 />
            <MainCOD1>
                  <Outlet />
            </MainCOD1>
            <FooterCOD1 />
      

      </>
}

const HeaderFooterCOD4Layout = () => {
      return <>

      <HeaderCOD4 />
      <MainCOD4>
            <Outlet />
      </MainCOD4>
      <FooterCOD4 />
      </>
}

// Ajoute cet import en haut du fichier router.jsx

const HeaderFooterLayoutTournamentValorant = () => {
      const [searchQuery, setSearchQuery] = useState(""); 
    
      const handleSearch = (query) => {
        setSearchQuery(query);
      };
    
      return (
        <>
          <HeaderValorant onSearch={handleSearch} />
          <MainValorant>
            <Outlet context={{ searchQuery }} /> 
          </MainValorant>
          <FooterValorant />
        </>
      );
    };
    
    

export const router = createBrowserRouter([
      {
            element: <HeaderFooterLayout />,
            errorElement: <ErrorPage />,
            children: [
                 {path: "/", element: <HallOfFame />},
                 {path: "/HallOfFame", element: <HallOfFame />},

            ]
      },
      {
            element: <HeaderFooterCod1Layout />,
            errorElement: <ErrorPage />,
            children: [
                  {path: "/tournament/cod1/teams", element: <TeamsCOD1 />},
                  {path: "/tournament/cod1/match", element: <MatchCOD1 />},
                  {path: "/tournament/cod1/classement", element: <ClassementCOD1 />},
                  {path: "/tournament/cod1/eliminatoire", element: <EliminatoireCOD1 />}
            ]
      }, 
      {
            element: <HeaderFooterCOD4Layout />,
            errorElement: <ErrorPage />,
            children: [
                  {path: "/tournament/cod4/caserne", element: <CaserneCOD4/>},
                  {path: "/tournament/cod4/rules", element: <RulesCOD4 />},
                  {path: "/tournament/cod4/recrues", element: <RecruesCOD4 />},
                  {path: "/tournament/cod4/teams", element: <TeamsCOD4 />},
                  {path: "/tournament/cod4/match", element: <MatchCOD4 />},
                  {path: "/tournament/cod4/classement", element: <ClassementCOD4 />},
                  {path: "/tournament/cod4/tirage", element: <TirageCOD44 />},
                  {path: "/tournament/cod4/tirage", element: <TirageCOD4 />},
                  {path: "/tournament/cod4/tiragemap", element: <TirageMapCOD4 />},
                  {path: "/tournament/cod4/images", element: <ImagesCOD4 />}
            ]
      }, 
      {
            element: <HeaderFooterLayoutTournamentValorant/>,
            errorElement: <ErrorPage />,
            children: [
                  {path: "/tournament/valorant/accueil", element: <AccueilValorant />},
                  {path: "/tournament/valorant/rules", element: <RulesValorant />},
                  {path: "/tournament/valorant/agents", element: <AgentsValorant/>},
                  {path: "/tournament/valorant/teams", element: <TeamsValorant/>},
                  {path: "/tournament/valorant/brackets", element: <BracketsValorant/>},
                  {path: "/tournament/valorant/leaderboard", element: <LeaderBoardValorant/>}
            ]
      }
])