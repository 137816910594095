import React from 'react';
import './TeamsCOD1.scss';

function TeamsCOD1() {
  return (
    <section className="cod1-teams">
            <img src={require("../../../assets/cod1/Background/team_banner.webp")} alt="Infos Serveur" className='banner'/>

            <div className='teams'>
                    <div className='container'>
                        <div className='cover-photo'>
                            <img src={require("../../../assets/cod1/player/ANIMA_LOGO.webp")} alt="Logo Anima" className='profile' />
                        </div>
                        <div className='profile-name'></div>
                        <p className='about'>aparT0707 • Nikoalbara • LeGuMeN <br /> Medattak • Mister_Ace</p>
                    </div>
                    <div className='container'>
                        <div className='cover-photo'>
                            <img src={require("../../../assets/cod1/player/lesgaulois_nobg.webp")} alt="Logo Les Gaulois" className='profile' />
                        </div>
                        <div className='profile-name'></div>
                        <p className='about'>Skypjeh • Canaa23 • Neus <br /> Nemo • Dividotgif</p>
                    </div>
                    <div className='container'>
                        <div className='cover-photo'>
                            <img src={require("../../../assets/cod1/player/lesptitsbits.webp")} alt="Logo Les P'tits Bits" className='profile' />
                        </div>
                        <div className='profile-name'></div>
                        <p className='about'>Anthrakys • Flammes256 • Xian_No <br /> Naked • Themen</p>
                    </div>
                    <div className='container'>
                        <div className='cover-photo'>
                            <img src={require("../../../assets/cod1/player/nemesis.webp")} alt="Logo Nemesis" className='profile' />
                        </div>
                        <div className='profile-name'></div>
                        <p className='about'>Wawcyrillamourette • AlexanderSV • Skz <br /> JayJay75013 • Franc3sc0_014</p>
                    </div>
                    <div className='container'>
                        <div className='cover-photo'>
                            <img src={require("../../../assets/cod1/player/Notime.webp")} alt="Logo N0t1me" className='profile' />
                        </div>
                        <div className='profile-name'></div>
                        <p className='about'>Twic_Ender • tugaalm • Wysix <br /> Mr_6ril • mBanania</p>
                    </div>
            </div>
    </section>  
  )
}

export default TeamsCOD1