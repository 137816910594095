import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './SearchBarValorant.scss';

function SearchBarValorant({ onSearch }) { // Recevoir onSearch
    const [searchQuery, setSearchQuery] = useState("");

    const handleInputChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        onSearch(query); // Appeler onSearch avec la valeur de l'input
    };

    return (
        <form className="search-bar" onSubmit={(e) => e.preventDefault()}> {/* Ne pas envoyer de formulaire */}
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
            <input 
                type="text" 
                placeholder="Rechercher..."
                value={searchQuery} 
                onChange={handleInputChange} 
            />
        </form>
    );
}

export default SearchBarValorant;
