import React from 'react';
import './HeaderValorant.scss';
import NavBarValorant from '../../../components/VALO-Components/NavBarValorant/NavBarValorant';


function HeaderValorant({ onSearch }) { // Passer onSearch en tant que prop
  return (
    <header className='header-valorant'>
      <NavBarValorant onSearch={onSearch} />
    </header>
  )
}

export default HeaderValorant;
