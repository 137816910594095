import React, { useState, useRef } from 'react';
import "./TirageMapCOD4.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const maps = [
    { src: require("../../../assets/cod4/maps/backlot.png"), nom: 'Backlot' },
    { src: require("../../../assets/cod4/maps/citystreets.png"), nom: 'City Streets' },
    { src: require("../../../assets/cod4/maps/vacant.png"), nom: 'Vacant' },
    { src: require("../../../assets/cod4/maps/crossfire.png"), nom: 'CrossFire' },
    { src: require("../../../assets/cod4/maps/strike.png"), nom: 'Strike' },
    { src: require("../../../assets/cod4/maps/crash.png"), nom: 'Crash' },
];

const TirageMapCOD4 = () => {
    const [isSpinning, setIsSpinning] = useState(false);
    const [result, setResult] = useState(null);
    const [visibleMaps, setVisibleMaps] = useState(maps); // State to track visible maps
    const carrouselRef = useRef(null); // Ref for accessing carrousel DOM element

    // Update the map counter based on the number of visible maps
    const updateMapCounter = () => {
        return `(${visibleMaps.length})`;
    };

    // Create remove buttons for each visible map
    const createRemoveButtons = () => {
        return visibleMaps.map((map, index) => (
            <button
                key={index}
                className="map-remove-button"
                onClick={() => removeMap(map)}
            >
                <FontAwesomeIcon icon={faTimes} />
                {map.nom}
            </button>
        ));
    };

    // Remove the selected map from visibleMaps
    const removeMap = (mapToRemove) => {
        const updatedVisibleMaps = visibleMaps.filter((map) => map.nom !== mapToRemove.nom);
        setVisibleMaps(updatedVisibleMaps);
    };

    // Handle the spinning and selecting a random map
    function handleSpinClick() {
        if (visibleMaps.length === 0 || isSpinning || !carrouselRef.current || !carrouselRef.current.children || carrouselRef.current.children.length === 0) return;
    
        setIsSpinning(true);
        let start = null;
        const elementWidth = carrouselRef.current.children[0].offsetWidth; // Accessing carrouselRef
        const resetPoint = elementWidth * visibleMaps.length; // Using visibleMaps length
        const animationDuration = 5000; // Animation duration in milliseconds
    
        function step(timestamp) {
            if (!start) start = timestamp;
            const elapsedTime = timestamp - start;
            const progress = elapsedTime % resetPoint;
            carrouselRef.current.style.transform = `translateX(-${progress}px)`; // Accessing carrouselRef
    
            if (elapsedTime < animationDuration) {
                requestAnimationFrame(step);
            } else {
                const selectedMapIndex = Math.floor(Math.random() * visibleMaps.length); // Using visibleMaps for random selection
                const selectedMap = visibleMaps[selectedMapIndex];
                setResult(selectedMap);
                setIsSpinning(false);
            }
        }
    
        requestAnimationFrame(step);
    }

    return (
        <div>
            <div className="title">
                <h1 className='title-tirage'>
                    Tirage des Maps{' '}
                    <span id="mapCounter">{updateMapCounter()}</span>{' '}
                </h1>
                <div id="boutonsMaps">{createRemoveButtons()}</div>
            </div>
            <div id="carrouselContainermap" className="carousel-containermap">
                <ul ref={carrouselRef} className="carouselmap">
                    {[...visibleMaps, ...visibleMaps, ...visibleMaps].map((map, index) => (
                        <li key={index}>
                            <img src={map.src} alt={map.nom} />
                        </li>
                    ))}
                </ul>
            </div>
            <div className="button">
                <button className="button-53" onClick={handleSpinClick}>
                    Lancer le tirage
                </button>
            </div>
            <div id="resultatTirage">
                {result && (
                    <div className='resultpadding'>
                        <h2 className='title-tirage'>{result.nom}</h2>
                        <img src={result.src} alt={result.nom} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default TirageMapCOD4;
