import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons'
import './navbarCOD4.scss';

function NavbarCOD4() {

  return (
    <nav className='navbarcod4'>
        <NavLink to="/tournament/cod4/caserne">CASERNE</NavLink>
        <div className='separator'>|</div>
        <NavLink to="/tournament/cod4/rules">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RÈGLES <br />INSTALLATION</NavLink>
        <div className='separator'>|</div>
        <NavLink to="/tournament/cod4/recrues">RECRUES</NavLink>
        <div className='separator'>|</div>
        <NavLink to="/tournament/cod4/teams">TEAMS</NavLink>
        <div className='separator'>|</div>
        <NavLink to="/tournament/cod4/match">MATCHS</NavLink>
        <div className='separator'>|</div>
        <NavLink to="/tournament/cod4/classement">CLASSEMENT</NavLink>
        <div className='separator'>|</div>
        <NavLink to="/HallOfFame" ><FontAwesomeIcon icon={faArrowRightToBracket} /></NavLink>
    </nav>
  )
}

export default NavbarCOD4
