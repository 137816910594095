import React, { useState, useEffect } from 'react';
import data from './Tirage4.json'; 
import './TirageCOD44.scss'; 

function TirageJoueurs() {
  const [equipes, setEquipes] = useState([]);
  const [joueursAleatoires, setJoueursAleatoires] = useState([]);
  const [joueurSelectionne, setJoueurSelectionne] = useState('');
  const [nombreJoueurs, setNombreJoueurs] = useState(0);
  const joueurAExclure = "N1co";

  useEffect(() => {
    initializeTeams();
    initializeRandomPlayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNombreJoueurs(joueursAleatoires.length); 
  }, [joueursAleatoires]);

  const initializeTeams = () => {
    const initialEquipes = data.equipes.map(equipe => ({
      ...equipe,
      joueurs: [],
      nbJoueurs: 0,
      equipeComplete: false
    }));
    setEquipes(initialEquipes);
  };

  const initializeRandomPlayers = () => {
    const remainingPlayers = data.joueurs || [];
    setJoueursAleatoires(shuffleArray(remainingPlayers));
  };

  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const tournerCarrousel = () => {
    const carrousel = document.getElementById('carrousel');
    const totalSpins = 30 + Math.floor(Math.random() * 10);

    const tournerBouton = document.querySelector('.button-53');
    tournerBouton.disabled = true;

    let spins = 0;
    const spinInterval = setInterval(() => {
      carrousel.appendChild(carrousel.firstElementChild);
      spins++;

      if (spins >= totalSpins) {
        clearInterval(spinInterval);

        let selectedPlayerElement = carrousel.firstElementChild;
        let selectedPlayer = selectedPlayerElement.textContent;

        // Ensure the selected player is not the excluded player
        while (selectedPlayer === joueurAExclure) {
          carrousel.appendChild(selectedPlayerElement);
          selectedPlayerElement = carrousel.firstElementChild;
          selectedPlayer = selectedPlayerElement.textContent;
        }

        selectedPlayerElement.classList.add('player_selected');
        setJoueurSelectionne(selectedPlayer);

        setTimeout(() => {
          carrousel.removeChild(selectedPlayerElement);
          addToTeam(selectedPlayer);
          tournerBouton.disabled = false;
        }, 2000);
      }
    }, 100);
  };

  const addToTeam = (player) => {
    const availableTeams = equipes.filter(equipe => !equipe.equipeComplete);
    
    if (availableTeams.length > 0) {
      const incompleteTeams = availableTeams.filter(equipe => equipe.nbJoueurs < 2); //4
  
      if (incompleteTeams.length > 0) {
        const index = Math.floor(Math.random() * incompleteTeams.length);
        const updatedEquipes = [...equipes];
        const teamToUpdate = updatedEquipes.find(equipe => equipe.nom === incompleteTeams[index].nom);
        
        teamToUpdate.joueurs.push(player);
        teamToUpdate.nbJoueurs++;
  
        if (teamToUpdate.nbJoueurs === 2) { //4
          teamToUpdate.equipeComplete = true;
        }
        setEquipes(updatedEquipes);
      }
    }
  };

  return (
    <div>
      <h1 className='title-tirage'>Tirage Joueurs ({nombreJoueurs})</h1> 
      
      <div id="carrouselContainer">
        <ul id="carrousel" className="carrousel">
          {joueursAleatoires.map((joueur, index) => (
            <li key={index} className={joueur === joueurSelectionne ? 'player_selected' : ''}>{joueur}</li>
          ))}
        </ul>
      </div>
      
      <button className="button-53" onClick={tournerCarrousel}>Tourner le Carrousel</button>

      <h2 className='title-tirage'>Équipes</h2>
      <div id="equipes">
        {equipes.map((equipe, index) => (
          <div key={index} className={equipe.equipeComplete ? 'equipe-complete' : 'equipe-incomplete'}>
            <strong>{equipe.nom}</strong>: {equipe.capitain.join(', ')}, {equipe.joueurs.join(', ')}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TirageJoueurs;
