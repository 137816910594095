import React from 'react';
import './ImagesCOD4.scss';

function ImagesCOD4COD4() {

  return (
    <section className="images-cod4">
      <img src={require("../../../assets/cod4/teams/1poop180.png")} alt='Logo Acac' className=''/>
      <img src={require("../../../assets/cod4/teams/Les_Tontons_Flingueurs_white.png")} alt='Logo Les Tontons Flingueurs' className=''/>
      <img src={require("../../../assets/cod4/teams/Nautilus_sans_logo.png")} alt='Logo Nautilus' className=''/>
      <img src={require("../../../assets/cod4/teams/pngwingwhite.com.png")} alt='Logo AllStars' className=''/>
      <img src={require("../../../assets/cod4/teams/wemakenoise.png")} alt='Logo WeMakeNoise' className=''/>
      <img src={require("../../../assets/cod4/EGALITE.png")} alt='Logo WeMakeNoise' className=''/>
      <img src={require("../../../assets/cod4/WINNER.png")} alt='Logo WeMakeNoise' className=''/>


    </section>
  );
}

export default ImagesCOD4COD4;

