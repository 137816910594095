import React from 'react';
import './header.scss';
import Logo from '../../../assets/LOGO_PIX_TOURNAMENT.webp'
import { NavLink } from 'react-router-dom';



function Header() {
  return (
    <header className='header'>
      <NavLink to="/HallOfFame">
        <div>
              <img className='logo' src={Logo} alt='Logo Lespixionautes.fr' />
        </div>
      </NavLink>
    </header>
  )
}

export default Header